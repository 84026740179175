import { useCallback, useContext, useState } from "react";
import { Link } from "react-router-dom";

import { Icon } from "@icg360/design-system";

import { AddressLoader } from "components/loader";
import LoggingOut from "components/logging-out";
import { AuthAppContext } from "components/root/auth-app-provider";
import { navElements } from "consts";
import { useLogout } from "hooks/use-logout-user";
import { en as locale } from "locale";

import styles from "./mobile-navigation.module.scss";

type MobileNavigationProps = {
  onPolicySelectorOpen: React.MouseEventHandler;
};

const MobileNavigation = ({ onPolicySelectorOpen }: MobileNavigationProps) => {
  const { policy, policies } = useContext(AuthAppContext);
  const [loggingOut, setLoggingOut] = useState(false);
  const { logout } = useLogout();

  const logoutOnClick = useCallback(
    async (e) => {
      e.stopPropagation();
      setLoggingOut(true);
      await logout();
    },
    [logout]
  );

  return (
    <>
      <div className={styles.navigation} data-testid="secondary-header">
        <button
          className={styles.policyAddressContainer}
          onClick={onPolicySelectorOpen}
        >
          {!policies?.length ? (
            <AddressLoader />
          ) : (
            <>
              <div className={styles.address}>
                {`${policy.propertyAddress?.street1} ${policy.propertyAddress?.street2}`}
              </div>
              <div className={styles.policyNumber}>
                Policy Number: {policy.policyId}
              </div>
              <div className={styles.switchLink}>
                Switch to a different policy
              </div>
            </>
          )}
        </button>

        <ul className={styles.menuItems}>
          {navElements.map((element, index) => {
            if (element.to === "/my/property" && policy.isCommercial) {
              return null;
            }
            return (
              <li key={index}>
                <Link to={element.to} className={styles.menuItem}>
                  <Icon name={element.iconName} size="sm" />
                  {element.title}
                </Link>
              </li>
            );
          })}
        </ul>

        <ul className={styles.profileMenuItems}>
          <li>
            <Link to="/my/settings" className={styles.menuItem}>
              <Icon name="Settings" size="sm" />
              {locale.navigation.settings}
            </Link>
          </li>
          <li>
            <button onClick={logoutOnClick}>
              <div className={styles.menuItem}>
                <Icon name="User" size="sm" />
                Log out
              </div>
            </button>
          </li>
        </ul>
      </div>
      {loggingOut ? <LoggingOut /> : null}
    </>
  );
};

export default MobileNavigation;
