const isNilOrZero = (val) => val == null || val === "0";

export const labelCoverages = (currentTerm) => {
  const {
    coverageA,
    coverageB,
    coverageC,
    coverageD,
    coverageE,
    coverageF,
    coverageL,
    coverageM,
    moldLimit,
    allOtherPerilsDeductible,
    hurricaneDeductible,
    windHailDeductible,
    identityFraudCoveragePremium,
    mechanicalBreakdownCoveragePremium,
  } = currentTerm ?? {};

  return {
    dwelling: coverageA,
    otherStructures: coverageB,
    personalProperty: coverageC,
    lossOfUse: coverageD,
    allOtherPerils: allOtherPerilsDeductible,
    moldLimit,
    hurricane: hurricaneDeductible,
    windHail: windHailDeductible,
    medicalPayments: isNilOrZero(coverageF) ? coverageM : coverageF,
    personalLiability: isNilOrZero(coverageE) ? coverageL : coverageE,
    identityFraud: identityFraudCoveragePremium,
    mechanicalBreakdown: mechanicalBreakdownCoveragePremium,
    ...currentTerm,
  };
};

type CoverageMap = {
  name: string;
  key: string;
  perValue?: string;
};

export type PolicyCoverageAndDeductibles = {
  deductibles: CoverageMap[];
  extendedCoverages: CoverageMap[];
  liabilityCoverages: CoverageMap[];
  propertyCoverages: CoverageMap[];
};

type PolicyTypeCoverageMap = {
  HO3: PolicyCoverageAndDeductibles;
  HO5: PolicyCoverageAndDeductibles;
  DP3: PolicyCoverageAndDeductibles;
  HWO: PolicyCoverageAndDeductibles;
  MHO: PolicyCoverageAndDeductibles;
  HO6: PolicyCoverageAndDeductibles;
};

const dwellingMap: CoverageMap = { name: "Dwelling", key: "dwelling" };
const otherStructuresMap: CoverageMap = {
  name: "Other structures",
  key: "otherStructures",
};
const personalPropertyMap: CoverageMap = {
  name: "Personal property",
  key: "personalProperty",
};
const lossOfUseMap: CoverageMap = { name: "Loss of use", key: "lossOfUse" };
const allOtherPerilsMap: CoverageMap = {
  name: "All other perils",
  key: "allOtherPerils",
};
const windHailMap: CoverageMap = {
  name: "Wind/Hail",
  key: "windHail",
};
const hurricaneMap: CoverageMap = {
  name: "Hurricane",
  key: "hurricane",
};
const personalLiabilityMap: CoverageMap = {
  name: "Personal liability",
  key: "personalLiability",
  perValue: "occurrence",
};
const medicalPaymentsMap: CoverageMap = {
  name: "Medical payments",
  key: "medicalPayments",
  perValue: "person",
};

// Note: Does not currently support BOP (commercial) policies.
export const policyTypeCoverageMap: PolicyTypeCoverageMap = {
  // HO3: Homeowners policy
  HO3: {
    propertyCoverages: [
      dwellingMap,
      otherStructuresMap,
      personalPropertyMap,
      lossOfUseMap,
    ],
    deductibles: [allOtherPerilsMap, windHailMap, hurricaneMap],
    liabilityCoverages: [personalLiabilityMap, medicalPaymentsMap],
    extendedCoverages: [
      {
        name: "Mold limit",
        key: "moldLimit",
      },
      {
        name: "Identity fraud expense",
        key: "identityFraud",
      },
      {
        name: "Equipment breakdown",
        key: "mechanicalBreakdown",
      },
    ],
  },

  // HO5: Comprehensive Homeowners policy
  HO5: {
    propertyCoverages: [
      dwellingMap,
      otherStructuresMap,
      personalPropertyMap,
      lossOfUseMap,
    ],
    deductibles: [allOtherPerilsMap, windHailMap, hurricaneMap],
    liabilityCoverages: [personalLiabilityMap, medicalPaymentsMap],
    extendedCoverages: [],
  },

  // DP3: Landlord policy
  DP3: {
    propertyCoverages: [
      dwellingMap,
      otherStructuresMap,
      personalPropertyMap,
      {
        name: "Fair rental value & additional living expense",
        key: "lossOfUse",
      },
    ],
    deductibles: [allOtherPerilsMap, windHailMap],
    liabilityCoverages: [personalLiabilityMap, medicalPaymentsMap],
    extendedCoverages: [
      {
        name: "Mold/Fungi",
        key: "moldLimit",
      },
    ],
  },

  // HWO: Wind-only policy
  HWO: {
    propertyCoverages: [
      dwellingMap,
      otherStructuresMap,
      personalPropertyMap,
      lossOfUseMap,
    ],
    deductibles: [windHailMap],
    liabilityCoverages: [],
    extendedCoverages: [],
  },

  // MHO: Mobile home policy
  MHO: {
    propertyCoverages: [
      dwellingMap,
      otherStructuresMap,
      personalPropertyMap,
      lossOfUseMap,
    ],
    deductibles: [allOtherPerilsMap],
    liabilityCoverages: [personalLiabilityMap, medicalPaymentsMap],
    extendedCoverages: [],
  },

  // HO6: Condo policy
  HO6: {
    propertyCoverages: [dwellingMap, personalPropertyMap, lossOfUseMap],
    deductibles: [allOtherPerilsMap, windHailMap],
    liabilityCoverages: [personalLiabilityMap, medicalPaymentsMap],
    extendedCoverages: [],
  },
};
