import { useContext, useState } from "react";
import { Col } from "react-bootstrap";
import { useForm } from "react-hook-form";

import {
  Badge,
  Button,
  Card,
  Heading,
  Icon,
  Span,
  Spinner,
  addToast,
} from "@icg360/design-system";

import { MSSLink } from "components/common/link";
import { CustomerCareContactModal } from "components/customer-care-contact-modal";
import { AuthAppContext } from "components/root/auth-app-provider";
import { InsuranceRepresentative } from "components/shared/insurance-representative";
import { MainLayout } from "components/shared/main-layout";
import { PageFooter } from "components/shared/page-footer";
import { ThumbtackModalProvider } from "components/thumbtack-modal";
import { CONFIG } from "config";
import { daysUntil, formatDate } from "utils";

import { CancelledPolicySidebar } from "./cancelled-policy-sidebar";
import { UnderwritingConditionItem } from "./underwriting-condition-item";
import { UploadDocumentsSidebar } from "./upload-documents-sidebar";
import styles from "./upload-documents.module.scss";
import { UploadSection } from "./upload-section";

export const UploadDocuments = () => {
  const { control, register, setValue, getValues } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [preparedFiles, setPreparedFiles] = useState<
    (File & { preview?: string })[]
  >([]);
  const { userDetails, selectedPolicy, userInfo } = useContext(AuthAppContext);

  const { email: userEmail } = userInfo ?? {};
  const {
    keystonePolicyId: insightPolicyId,
    propertyAddress,
    policyStatus,
  } = userDetails ?? {};
  const { zip } = propertyAddress ?? {};
  const { pendingCancellationDate } = selectedPolicy ?? {};
  const { referralList, dueDate } =
    selectedPolicy?.inspection?.newBusinessInspectionWriteOuts ?? {};
  const isCancelled = policyStatus === "CANCELLEDPOLICY";
  const numberOfDays = daysUntil(dueDate);
  const proxyUploadURL = `${CONFIG.KEYSTONE_PROXY_HREF}/api/write-out-upload`;
  const TEN_THOUSAND = 10000;

  let headingText = "Things we need you to fix";
  let badgeText = `Due in ${numberOfDays} day${numberOfDays !== 1 ? "s" : ""}.`;
  let badgeAppearance: "warning" | "danger" | "neutral" = "warning";
  if (isCancelled) {
    headingText = "Your policy has been cancelled.";
    badgeText = "Policy cancelled";
    badgeAppearance = "neutral";
  } else if (pendingCancellationDate) {
    headingText = "Please fix the following issues to keep your policy active";
    badgeText = "Pending cancellation";
    badgeAppearance = "danger";
  }

  const resetFiles = () => {
    preparedFiles.forEach(
      (file) => file.preview && URL.revokeObjectURL(file.preview)
    );
    setValue("files", undefined);
    setPreparedFiles([]);
  };

  const removeFile = (fileIndex) => {
    const fileToBeRemoved = preparedFiles[fileIndex];
    fileToBeRemoved.preview && URL.revokeObjectURL(fileToBeRemoved.preview);
    const newList = getValues("files");
    newList.splice(fileIndex, 1);
    setValue("files", newList, { shouldTouch: true });
    setPreparedFiles(newList);
  };

  const onSubmit = async () => {
    setSubmitting(true);

    try {
      for (const file of preparedFiles) {
        const fileType = file.type || "text/plain";
        const formData = new FormData();
        formData.append("upload-type", "file");
        formData.append("content-type", fileType);
        formData.append("file", file);
        formData.append("insightPolicyId", insightPolicyId || "");
        formData.append("documentType", "INSPECTION_DOCUMENT");
        formData.append("userEmail", userEmail || "");

        await fetch(proxyUploadURL, {
          method: "POST",
          credentials: "include",
          body: formData,
        });

        file.preview && URL.revokeObjectURL(file.preview);
      }
    } catch (e) {
      addToast("There was an error uploading your files.", {
        duration: TEN_THOUSAND,
      });
      setSubmitting(false);
      return;
    }

    addToast("Proof of repair submitted.", {
      icon: true,
      duration: TEN_THOUSAND,
    });
    setShowSuccessAlert(true);
    resetFiles();
    setSubmitting(false);
  };

  return (
    <ThumbtackModalProvider>
      <div
        className={submitting ? styles.loadingActive : styles.loadingOverlay}
      >
        {submitting && <Spinner className={styles.loadingSpinner} />}

        <MainLayout
          topRow={
            <Col md={12} sm={12} xs={12}>
              <div className={styles.heading}>
                <MSSLink to="/my/overview">
                  <div className={styles.backArrow}>
                    <Icon size="sm" name="Back" />
                  </div>
                  Back to Policy Overview
                </MSSLink>
                <div className={styles.headerContainer}>
                  <Heading size="lg" className={styles.mainHeading}>
                    {headingText}
                  </Heading>
                  <div className={styles.dueDateBadge}>
                    <Badge
                      appearance={badgeAppearance}
                      icon={isCancelled ? undefined : "Clock"}
                    >
                      {badgeText}
                    </Badge>
                  </div>
                </div>
                {pendingCancellationDate ? (
                  <Span>
                    Your policy is set to cancel on{" "}
                    {formatDate(pendingCancellationDate, "MM/DD/YYYY")}. Please
                    repair the issues or contact your agent about next steps.
                  </Span>
                ) : null}
              </div>
            </Col>
          }
          sidebar={
            <div className={styles.sidebarCards}>
              {isCancelled ? (
                <CancelledPolicySidebar />
              ) : (
                <UploadDocumentsSidebar
                  setShowContactModal={setShowContactModal}
                />
              )}
              {showSuccessAlert && <InsuranceRepresentative />}
            </div>
          }
        >
          <Card className={styles.mainCard}>
            {referralList &&
              referralList.map((referral, idx) => {
                const { underwritingCondition } = referral ?? {};
                return underwritingCondition ? (
                  <UnderwritingConditionItem
                    underwritingCondition={underwritingCondition}
                    zipCode={zip}
                    key={idx}
                  />
                ) : null;
              })}

            <UploadSection
              preparedFiles={preparedFiles}
              setPreparedFiles={setPreparedFiles}
              submitting={submitting}
              showSuccessAlert={showSuccessAlert}
              formControl={{ control, register, setValue }}
            />

            {preparedFiles.length > 0 ? (
              <div className={styles.preparedFileSection}>
                <div className={styles.preparedFiles}>
                  {preparedFiles.map((file, idx) => (
                    <div className={styles.previewContainer} key={idx}>
                      {file.type === "application/pdf" ? (
                        <div className={styles.filePreview}>
                          <Icon name="FilePdf" />
                          <Span size="micro">{file.name}</Span>
                        </div>
                      ) : (
                        <img
                          src={file.preview}
                          className={styles.thumbnailPreview}
                          alt="thumbnail preview"
                        />
                      )}

                      <Button
                        className={styles.thumbnailButton}
                        onClick={() => removeFile(idx)}
                        disabled={submitting}
                        size="xs"
                        icon="Delete"
                        aria-label="Delete_item"
                        data-testid="writeouts-remove-file-btn"
                      />
                    </div>
                  ))}
                </div>
                <Button
                  className={styles.submitButton}
                  disabled={submitting}
                  loading={submitting}
                  onClick={onSubmit}
                  data-testid="writeouts-uploads-done-btn"
                >
                  Share with SageSure
                </Button>
              </div>
            ) : null}
          </Card>
          <CustomerCareContactModal
            show={showContactModal}
            handleClose={() => setShowContactModal(false)}
            description="We can assist with any questions about billing or your SageSure account."
          />
        </MainLayout>
      </div>
      <PageFooter />
    </ThumbtackModalProvider>
  );
};
