import { useContext } from "react";
import { Col, Row } from "react-bootstrap";

import { Badge, Heading, Span, Tooltip, UiIcon } from "@icg360/design-system";

import { SectionCard } from "components/common/card/card";
import { MSSLink } from "components/common/link";
import { CommercialCoverage } from "components/renderers";
import { AuthAppContext } from "components/root/auth-app-provider";
import {
  capitalizeEachWord,
  formatCurrency,
  formatDate,
  logError,
} from "utils";

import styles from "./coverage.module.scss";
import {
  PolicyCoverageAndDeductibles,
  labelCoverages,
  policyTypeCoverageMap,
} from "./policy-type-coverage-map";

export const Coverage = () => {
  const { userDetails, userPolicyProperties } = useContext(AuthAppContext);
  const { isCommercial, policyStatus, propertyAddress, currentTerm } =
    userDetails ?? { currentTerm: {} };
  const { policyType } = userPolicyProperties ?? {};
  const { effectiveDate, expirationDate, totalPremium } = currentTerm ?? {};
  // Use the old component for BOP policies:
  if (isCommercial) {
    return <CommercialCoverage />;
  }

  // Give the coverages more descriptive names:
  const labelledCoverages = labelCoverages(currentTerm);
  // Generate which coverages to show based on policyType:
  const coverageMap: PolicyCoverageAndDeductibles =
    policyTypeCoverageMap[policyType ?? ""];

  if (!coverageMap) {
    logError(`Unknown policyType: ${policyType}`);
    return null;
  }

  const {
    deductibles,
    extendedCoverages,
    liabilityCoverages,
    propertyCoverages,
  } = coverageMap ?? {};

  let policyStatusDisplay = "";
  if (RegExp(/(^active)/gi).test(policyStatus ?? "")) {
    policyStatusDisplay = "Active";
  } else if (RegExp(/(^nonrenewed)/gi).test(policyStatus ?? "")) {
    policyStatusDisplay = "Nonrenewed";
  } else {
    policyStatusDisplay = "Cancelled";
  }

  const coverageValueDisplay = (coverageValue) => {
    return coverageValue && coverageValue !== "0"
      ? formatCurrency(coverageValue)
      : "N/A";
  };

  const showExtendedCoverages = extendedCoverages?.some((coverage) => {
    const coverageValue = labelledCoverages[coverage.key];
    return coverageValueDisplay(coverageValue) !== "N/A";
  });

  const addressDisplay = (addressObject) => {
    const { street1, street2, city, state, zip } = addressObject ?? {};
    return (
      <>
        {capitalizeEachWord(street1)}
        <br />
        {street2 && (
          <>
            , {capitalizeEachWord(street2)}
            <br />
          </>
        )}
        {capitalizeEachWord(city)}, {state} {zip}
      </>
    );
  };

  const createDeductibleEntry = (label: string, value: string) => {
    const isApplicable = value !== "N/A" && value !== "";
    const isPercentage = value && value.includes("%");
    return (
      <div className={styles.section} key={label}>
        <Span bold size="lg">
          {isApplicable ? value : "N/A"}
        </Span>
        {isApplicable && isPercentage ? (
          <div className={styles.sectionTooltipWrapper}>
            <Tooltip content={`${value} of Dwelling`}>
              <UiIcon name="Question" />
            </Tooltip>
          </div>
        ) : null}
        <br />
        {label}
      </div>
    );
  };

  const createCoverageEntry = (
    label: string,
    value: string,
    perValue?: false | string
  ) => (
    <div className={styles.section} key={label}>
      <Span bold size="lg">
        {coverageValueDisplay(value)}
      </Span>
      {perValue && coverageValueDisplay(value) !== "N/A" ? (
        <Span size="md"> / {perValue}</Span>
      ) : null}
      <br />
      {label}
    </div>
  );

  const CoverageLayout = () => (
    <>
      <Row className={styles.firstRow}>
        <Col lg={7} md={12}>
          <Heading size="xs" className={styles.subHeading}>
            Insured property
          </Heading>
          <Span size="lg" bold>
            {addressDisplay(propertyAddress)}
          </Span>
        </Col>

        <Col lg={5} md={12}>
          <Heading size="xs" className={styles.subHeading}>
            Total premium
          </Heading>
          <Span size="lg" bold>
            {formatCurrency(totalPremium, true)}{" "}
          </Span>
          <Span size="md">/ year</Span>
        </Col>
      </Row>

      <Row className={styles.secondRow}>
        <Col xl={7} lg={12}>
          <Heading size="xs" className={styles.subHeading}>
            Property coverage (Section I)
          </Heading>
          <div className={styles.gridContainer}>
            {propertyCoverages?.map((coverage) =>
              createCoverageEntry(
                coverage.name,
                labelledCoverages && labelledCoverages[coverage.key],
                coverage.perValue
              )
            )}
          </div>
        </Col>

        <Col xl={5} lg={12}>
          <Heading size="xs" className={styles.subHeading}>
            Deductibles
            <div className={styles.tooltipWrapper}>
              <Tooltip content="The amount you pay before we cover your loss under Property Coverage">
                <UiIcon name="Question" />
              </Tooltip>
            </div>
          </Heading>
          <div className={styles.gridContainer}>
            {deductibles?.map((deductible) =>
              createDeductibleEntry(
                deductible.name,
                labelledCoverages && labelledCoverages[deductible.key]
              )
            )}
          </div>
        </Col>
      </Row>

      {liabilityCoverages.length > 0 ? (
        <Row className={styles.thirdRow}>
          <Col xl={7} lg={12}>
            <Heading size="xs" className={styles.subHeading}>
              Liability coverage (Section II)
            </Heading>

            <div className={styles.liabilityGrid}>
              {liabilityCoverages?.map((liabilityCoverage) =>
                createCoverageEntry(
                  liabilityCoverage.name,
                  labelledCoverages && labelledCoverages[liabilityCoverage.key],
                  liabilityCoverage.perValue
                )
              )}
            </div>
          </Col>
        </Row>
      ) : null}

      <Row className={styles.fourthRow}>
        <Col xl={7} lg={12}>
          <Heading size="xs" className={styles.subHeading}>
            Extended coverage
          </Heading>

          {showExtendedCoverages ? (
            <div className={styles.extendedCoveragesGrid}>
              {
                // Slice extendedCoverages to maintain alignment with other sections
                extendedCoverages
                  ?.slice(0, 2)
                  .map((extendedCoverage) =>
                    createCoverageEntry(
                      extendedCoverage.name,
                      labelledCoverages &&
                        labelledCoverages[extendedCoverage.key],
                      extendedCoverage.perValue
                    )
                  )
              }
            </div>
          ) : (
            <div className={styles.noExtended}>
              <Span bold size="lg">
                None
              </Span>
            </div>
          )}
        </Col>

        {showExtendedCoverages && extendedCoverages.length > 2 ? (
          <Col xl={5} lg={12} className={styles.extendedCoveragesLeftColumn}>
            <div className={styles.extendedCoveragesGrid}>
              {extendedCoverages
                .slice(2)
                .map((extendedCoverage) =>
                  createCoverageEntry(
                    extendedCoverage.name,
                    labelledCoverages &&
                      labelledCoverages[extendedCoverage.key],
                    extendedCoverage.perValue
                  )
                )}
            </div>
          </Col>
        ) : null}
      </Row>

      <div className={styles.documents}>
        <Heading size="xs" className={styles.documentsHeading}>
          Policy documents and endorsements
        </Heading>
        <Span className={styles.declarations}>
          View more details in <MSSLink to="/my/documents">Documents</MSSLink>.
          To make changes to your policy, contact your agent.
        </Span>
      </div>
    </>
  );

  return (
    <SectionCard
      title={
        <div className={styles.mainHeading}>
          <span className={styles.leftHeading}>
            <Heading size="md">
              Your policy
              <Span
                color={
                  new Date(expirationDate) > new Date() ? "success" : "quiet"
                }
                bold
                className={styles.policyPeriod}
              >
                {formatDate(effectiveDate, "MM/DD/YYYY")}-
                {formatDate(expirationDate, "MM/DD/YYYY")}
              </Span>
            </Heading>
          </span>

          <Badge
            appearance={
              policyStatusDisplay === "Active" ? "success" : "neutral"
            }
          >
            {policyStatusDisplay}
          </Badge>
        </div>
      }
    >
      <CoverageLayout />
    </SectionCard>
  );
};
