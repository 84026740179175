import { WatchQueryFetchPolicy } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { useLoaderData, useSearchParams } from "react-router-dom";

import {
  AuthAppContextInterface,
  AuthAppContextState,
} from "components/root/auth-app-provider";
import {
  useCommonPolicyDataLazyQuery,
  useGetPolicyLazyQuery,
  useUserPolicyIDsLazyQuery,
} from "gql/__generated__/hooks";
import {
  type KeystoneCache,
  type KeystonePolicyInfo,
  authUser,
  getCookie,
  logError,
  setMixpanelProfileProperties,
  setMixpanelSuperProperties,
  useFlags,
} from "utils";

export const useAuthAppData = (
  state: AuthAppContextState,
  {
    setPolicies,
    setSelectedPolicy,
    setUserBilling,
    setUserDetails,
    setUserDocuments,
    setUserInfo,
    setUserPolicyProperties,
    setUserInsuranceRep,
    setPolicy,
  }: Omit<AuthAppContextInterface, "refetchPolicy" | "refetchPolicies">,
  setIsPolicyDataReady: (isPolicyDataReady: boolean) => void
) => {
  const { retireProxy, universalLogin } = useFlags();
  const email = authUser()?.email ?? "";
  const loaderData = useLoaderData() as KeystoneCache;
  const [searchParams, setSearchParams] = useSearchParams();
  const [getPolicyQuery] = useGetPolicyLazyQuery({
    context: {
      clientName: "keystone-api",
    },
  });
  const [commonPolicyDataQuery] = useCommonPolicyDataLazyQuery({
    variables: { email, policyID: state.selectedPolicyId },
  });
  const [userPolicyIDsQuery] = useUserPolicyIDsLazyQuery({
    variables: { email },
  });

  const [getPolicyIsDone, setGetPolicyIsDone] = useState(false);
  const [commonPolicyDataIsDone, setCommonPolicyIsDone] = useState(false);

  // policyFetchCount is used purely to trigger a useEffect below every time the policy changes
  // Without it, switching to a cached policy doesn't trigger it we get stuck in loading
  const [policyFetchCount, setPolicyFetchCount] = useState(0);

  // throwing an error inside these "do<query>Query" functions doesn't get caught by the error boundaries
  // So, we set this state and throw here
  const [asyncError, setAsyncError] = useState<Error | null>(null);
  useEffect(() => {
    if (asyncError) {
      logError(`Use auth app data: ${asyncError.message}`);
      throw asyncError;
    }
  }, [asyncError]);

  const doCommonPolicyDataQuery = useCallback(
    async (fetchPolicy: WatchQueryFetchPolicy = "cache-first") => {
      setCommonPolicyIsDone(false);
      const { data, error } = await commonPolicyDataQuery({
        variables: { policyID: state.selectedPolicyId, email },
        fetchPolicy,
      });

      if (error) {
        setAsyncError(error);
        return;
      }
      if (retireProxy && universalLogin) {
        setUserBilling(loaderData?.policyDetails?.userBilling);
        setUserDetails(loaderData?.policyDetails?.userDetails);
        setUserDocuments(loaderData?.policyDetails?.userDocuments);
        setUserInfo(loaderData?.user);
        setUserPolicyProperties(
          loaderData?.policyDetails?.userPolicyProperties
        );
        setUserInsuranceRep(loaderData?.policyDetails?.userInsuranceRep);
      } else {
        setUserBilling(data?.userBilling);
        setUserDetails(data?.userDetails);
        setUserDocuments(data?.userDocuments);
        setUserInfo(data?.userBasicInfo);
        setUserPolicyProperties(data?.userPolicyProperties);
        setUserInsuranceRep(data?.userInsuranceRep);
      }

      setCommonPolicyIsDone(true);
    },
    [
      loaderData?.user,
      loaderData?.policyDetails?.userBilling,
      loaderData?.policyDetails?.userDetails,
      loaderData?.policyDetails?.userDocuments,
      loaderData?.policyDetails?.userInsuranceRep,
      loaderData?.policyDetails?.userPolicyProperties,
      retireProxy,
      universalLogin,
      commonPolicyDataQuery,
      setUserBilling,
      setUserDetails,
      setUserDocuments,
      setUserInfo,
      setUserInsuranceRep,
      setUserPolicyProperties,
      state.selectedPolicyId,
      email,
    ]
  );

  const doGetPolicyQuery = useCallback(
    async (fetchPolicy: WatchQueryFetchPolicy = "cache-first") => {
      setGetPolicyIsDone(false);
      const { data, error } = await getPolicyQuery({
        variables: {
          id: state.selectedPolicyId,
        },
        fetchPolicy,
      });
      if (error) {
        setAsyncError(error);
        return;
      }
      setSelectedPolicy(data?.getPolicy);
      setGetPolicyIsDone(true);
    },
    [getPolicyQuery, setSelectedPolicy, state.selectedPolicyId]
  );

  useEffect(() => {
    if (!state.selectedPolicyId) {
      return;
    }
    setPolicyFetchCount((prev) => prev + 1);
    doGetPolicyQuery();
    doCommonPolicyDataQuery();
  }, [doGetPolicyQuery, doCommonPolicyDataQuery, state.selectedPolicyId]);

  const checkForPolicyInSearchParams = useCallback(
    (policies = state.policies) => {
      if (searchParams.get("policyNumber") || searchParams.get("ipId")) {
        const validPolicy = policies?.find(
          (userPolicy) =>
            userPolicy.policyId === searchParams.get("policyNumber") ||
            userPolicy.insightPolicyId === searchParams.get("ipId")
        );

        if (validPolicy) {
          setPolicy(validPolicy);
          // we only remove the qs param if it's valid so that error messages can use it
          // and because this can fire before state.policies is populated which is a "false invalid"
          setSearchParams((params) => {
            params.delete("policyNumber");
            params.delete("ipId");
            return params;
          });
          return true;
        }
      }
      return false;
    },
    [searchParams, setPolicy, setSearchParams, state.policies]
  );

  useEffect(() => {
    checkForPolicyInSearchParams();
  }, [checkForPolicyInSearchParams]);

  const doUserPolicyIDsQuery = useCallback(
    async (fetchPolicy: WatchQueryFetchPolicy = "cache-first") => {
      let userPolicies: KeystonePolicyInfo[] = [];

      if (retireProxy && universalLogin && loaderData.policies) {
        userPolicies = loaderData.policies;
      } else {
        const { data, error } = await userPolicyIDsQuery({ fetchPolicy });
        if (error) {
          setAsyncError(error);
          return;
        }
        userPolicies = data?.userPolicies ?? [];
      }

      if (!userPolicies?.length) {
        const error = new Error(
          "No policyIDs returned from userPolicyIDsQuery"
        );
        setAsyncError(error);
        return;
      }
      setPolicies(userPolicies);
      setMixpanelSuperProperties({
        numberOfPolicies: userPolicies.length,
      });
      setMixpanelProfileProperties({
        numberOfPolicies: userPolicies.length,
      });

      // If there's a policy number/insight ID in the url search params, use that
      if (checkForPolicyInSearchParams(userPolicies)) {
        return;
      }

      // selectedPolicyId is already set to a valid policy, don't override it
      if (
        state.selectedPolicyId &&
        userPolicies?.find(
          (userPolicy) => userPolicy.policyId === state.selectedPolicyId
        )
      ) {
        return;
      }

      if (retireProxy && universalLogin) {
        // If policy isn't set here, the loader would have already redirected to login
        if (loaderData.policy) {
          setPolicy(loaderData.policy);
          return;
        }
      } else {
        // policyNumber is in a cookie, from a previous session
        const policyIdCookie = getCookie("mss-selected-policy");
        if (policyIdCookie !== undefined) {
          try {
            const policy = JSON.parse(atob(policyIdCookie));
            const isValidPolicy = userPolicies?.find(
              (userPolicy) => userPolicy.policyId === policy.policyId
            );

            if (isValidPolicy) {
              setPolicy(policy);
              return;
            }
          } catch (e) {
            // We'll ignore this error and just try to set the default below
            logError(`error parsing policyID cookie: ${e.message}`);
          }
        }

        // If all else fails, use the first one in the list
        const defaultPolicy = userPolicies?.[0];
        if (defaultPolicy) {
          setPolicy(defaultPolicy);
          return;
        }
      }
      throw new Error("No default policy to set");
    },
    [
      checkForPolicyInSearchParams,
      loaderData?.policies,
      loaderData?.policy,
      retireProxy,
      setPolicies,
      setPolicy,
      state.selectedPolicyId,
      universalLogin,
      userPolicyIDsQuery,
    ]
  );

  const refetchPolicy = useCallback(() => {
    doGetPolicyQuery("no-cache");
    doCommonPolicyDataQuery("no-cache");
  }, [doGetPolicyQuery, doCommonPolicyDataQuery]);

  const refetchPolicies = useCallback(() => {
    doUserPolicyIDsQuery("no-cache");
  }, [doUserPolicyIDsQuery]);

  useEffect(() => {
    doUserPolicyIDsQuery();
  }, [doUserPolicyIDsQuery]);

  useEffect(() => {
    if (!policyFetchCount) {
      return;
    }
    setIsPolicyDataReady(getPolicyIsDone && commonPolicyDataIsDone);
  }, [
    getPolicyIsDone,
    commonPolicyDataIsDone,
    setIsPolicyDataReady,
    policyFetchCount,
  ]);

  return { refetchPolicy, refetchPolicies };
};
