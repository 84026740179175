import PropTypes from "prop-types";

import { Card } from "@icg360/design-system";

import { Body } from "components/common/body";
import { DataDefinition, DataRow, DataTerm } from "components/common/data-row";
import { MSSLink } from "components/common/link";
import { PaperlessCard } from "components/shared/paperless-card";
import { userBillingProp, userDetailsProp } from "consts";
import { en as locale } from "locale";
import { isPaperlessDocumentEligible } from "utils";

import styles from "./paperless-documents-enroll-widget.module.scss";

const PaperlessDocumentsEnrollWidget = ({
  errors,
  GetUserDetails: { userDetails },
}) => {
  if (errors) {
    return <Body>{locale.help.errorOccured}</Body>;
  }

  const isEnrolled = !isPaperlessDocumentEligible(userDetails);

  const {
    insuredPreferences: {
      opInsuredEmailAddress,
      opInsuredEmailAddressDocuments,
      opInsuredEmailAddressBilling,
    },
  } = userDetails;

  const userPolicyDocumentsEmail =
    opInsuredEmailAddressDocuments || opInsuredEmailAddress;

  const userBillingEmail =
    opInsuredEmailAddressBilling || opInsuredEmailAddress;

  const differentPaperlessEmails =
    userPolicyDocumentsEmail !== userBillingEmail;

  return (
    <>
      {isEnrolled ? (
        <Card title="Documents information">
          <DataRow className={styles.data}>
            <DataTerm className={styles.modalTriggerWrapper}>
              {differentPaperlessEmails
                ? locale.dataTable.policyDocumentsEmail
                : locale.dataTable.documentsEmail}
              :
            </DataTerm>
            <DataDefinition>{userPolicyDocumentsEmail}</DataDefinition>
          </DataRow>
          {differentPaperlessEmails && (
            <DataRow className={styles.data}>
              <DataTerm>{locale.dataTable.billingEmail}:</DataTerm>
              <DataDefinition>{userBillingEmail}</DataDefinition>
            </DataRow>
          )}
          <div className={styles.settingsLink}>
            {locale.dataTable.documentsSettings(MSSLink)}
          </div>
        </Card>
      ) : (
        <PaperlessCard pageName={locale.titles.documents} />
      )}
    </>
  );
};

PaperlessDocumentsEnrollWidget.propTypes = {
  errors: PropTypes.bool,
  GetUserDetails: PropTypes.shape(userDetailsProp),
  GetUserBilling: PropTypes.shape(userBillingProp),
};

export default PaperlessDocumentsEnrollWidget;
