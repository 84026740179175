import PropTypes from "prop-types";

import { Card, Link as DSLink, Icon } from "@icg360/design-system";

import { CONFIG } from "config";
import { userBillingProp, userDocumentsProp } from "consts";
import { en as locale } from "locale";
import { getKeyDocs, trackEvent } from "utils";

import styles from "./policy-docs.module.scss";

const PolicyDocs = ({
  GetUserDocuments: { userDocuments },
  GetUserBilling: { userBilling },
}) => {
  const keyDocsList = getKeyDocs(
    userDocuments,
    userBilling.mortgagees.length > 0
  );

  return (
    <Card title="Key documents">
      <div className={styles.policyDocuments}>
        Access and print important documents for your personal records or to
        show proof of insurance. If you have questions about the information on
        the policy&apos;s declaration page, please contact your agent for
        assistance.
      </div>
      <div className={styles.cardFooter}>
        {keyDocsList.map(({ route, label, name }, idx) => (
          <DSLink
            key={idx}
            href={`${CONFIG.KEYSTONE_PROXY_HREF}${route}`}
            filename={locale.documents[name]}
            type="PDF"
            name={label}
            target="_blank"
            rel="noreferrer noopener"
            className={styles.downloadLink}
            onClick={() => {
              if (name === "policyDeclarations") {
                trackEvent("Declaration Page Link Clicked", {
                  sectionName: "Key Documents",
                });
              }
            }}
          >
            {locale.documents[name]}
            <span className={styles.downloadIcon}>
              <Icon name="Download" size="sm" />
            </span>
          </DSLink>
        ))}
      </div>
    </Card>
  );
};

PolicyDocs.propTypes = {
  GetUserDocuments: PropTypes.shape(userDocumentsProp),
  GetUserBilling: PropTypes.shape(userBillingProp),
};

export default PolicyDocs;
