import { useContext } from "react";

import { Icon } from "@icg360/design-system";

import { MSSLink } from "components/common/link";
import {
  AuthAppContext,
  AuthAppDispatchContext,
} from "components/root/auth-app-provider";

import styles from "./mobile-policy-selector.module.scss";

const MobilePolicySelector = () => {
  const { policies } = useContext(AuthAppContext);
  const { setPolicy } = useContext(AuthAppDispatchContext);
  return policies ? (
    <div className={styles.policySelectorWrapper}>
      <div className={styles.policySelector}>
        <div className={styles.header}>Choose policy</div>
        {policies.map((policy) => {
          const { propertyAddress, policyId } = policy ?? {};
          return (
            <button
              key={policyId}
              className={styles.policy}
              onClick={() => {
                setPolicy(policy);
              }}
            >
              <div className={styles.policyData}>
                <div className={styles.address}>{propertyAddress?.street1}</div>
                {propertyAddress?.street2 ? (
                  <div className={styles.address}>
                    {propertyAddress?.street2}
                  </div>
                ) : null}
                <div className={styles.address}>
                  {`${propertyAddress?.city}, ${propertyAddress?.state} ${propertyAddress?.zip}`}
                </div>
                <div className={styles.policyNumber}>
                  {`Policy number: ${policyId}`}
                </div>
              </div>
              <Icon name="ArrowRight" />
            </button>
          );
        })}
        <MSSLink className={styles.addPolicyLink} to="/my/settings/policies">
          <div className={styles.addPolicyIcon}>
            <Icon name="AddCircle" />
          </div>
          <div className={styles.addPolicyLabel}>Add policy</div>
        </MSSLink>
      </div>
    </div>
  ) : null;
};

export default MobilePolicySelector;
