import moment from "moment";
import { Col, Container, Row } from "react-bootstrap";

import { Icon } from "@icg360/design-system";

import { CommonPolicyDataQuery } from "gql/__generated__/hooks";
import { formatCurrency, formatDate } from "utils";

import styles from "./scheduled-payment-notification.module.scss";

type ScheduledPaymentNotificationProps = {
  scheduledPayments: NonNullable<
    NonNullable<
      NonNullable<CommonPolicyDataQuery["userBilling"]>["pendingChanges"]
    >["ScheduledPayments"]
  >["scheduledPaymentList"];
};

const ScheduledPaymentNotification = ({
  scheduledPayments,
}: ScheduledPaymentNotificationProps) => {
  const prevDay = moment().utc().subtract(1, "days");

  return (
    <>
      {scheduledPayments?.map((payment, i) => {
        const isUpcoming = moment(payment.paymentDate).isAfter(prevDay);
        //Issue with timezone
        const paymentDate = formatDate(
          payment.paymentDate?.replace("T00:00:00", "")
        );
        return isUpcoming ? (
          <Container className={styles.scheduledPaymentWrap} key={i}>
            <Row>
              <Col xs={12}>
                <div className={styles.scheduledPaymentNotification}>
                  <div className={styles.scheduledPaymentHeader}>
                    <Icon name="Information" />
                    <div className={styles.label}>Scheduled Payment.</div>
                  </div>

                  <span className={styles.scheduledPaymentLabel}>
                    A payment of{" "}
                    {formatCurrency(payment.totalScheduledAmount, true)} is
                    scheduled for {paymentDate}.
                  </span>
                </div>
              </Col>
            </Row>
          </Container>
        ) : null;
      })}
    </>
  );
};

export default ScheduledPaymentNotification;
