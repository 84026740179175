import cx from "classnames";
import cloneDeep from "lodash.clonedeep";
import moment from "moment";
import PropTypes from "prop-types";
import { Fragment, useContext, useEffect, useState } from "react";
import { Collapse } from "react-collapse";

import { Badge, Icon } from "@icg360/design-system";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/common/table";
import { ClaimDetails } from "components/renderers";
import { AuthAppContext } from "components/root/auth-app-provider";
import { en as locale } from "locale";

import styles from "./claim-activity.module.scss";

const ClaimsList = ({ userClaimsList, userClaimsConstants }) => {
  const { userDetails } = useContext(AuthAppContext);

  const [claimsList, setClaimsList] = useState([]);
  const [claimsExpanded, setClaimsExpanded] = useState({});
  const toggleClaim = (key) =>
    setClaimsExpanded({
      ...claimsExpanded,
      [key]: !claimsExpanded[key],
    });

  useEffect(() => {
    if (userClaimsList) {
      let { ClaimsInfo: claimsInfo, SubmissionsInfo: submissionsInfo } =
        userClaimsList;
      const initialClaimsExpanded = {};

      claimsInfo = claimsInfo
        ? claimsInfo.map((origClaim) => {
            const claim = cloneDeep(origClaim);
            claim.dateOfLossObject = moment(claim.DateOfLoss);
            claim.dateSubmitted = moment(claim.DateReceived);
            claim.key = `claim${claim.ClaimNumber}`;
            initialClaimsExpanded[claim.key] = false;
            return claim;
          })
        : [];

      submissionsInfo = submissionsInfo
        ? submissionsInfo.map((origSubmission, index) => {
            const submission = cloneDeep(origSubmission);
            submission.dateOfLossObject = moment(submission.DateOfLoss);
            submission.dateSubmitted = moment(submission.DateReceived);
            submission.key = `submission${index}`;
            initialClaimsExpanded[submission.key] = false;

            return submission;
          })
        : [];

      let remainingSubmissions = cloneDeep(submissionsInfo);

      const claimsSubmission = claimsInfo.map((claim) => {
        const submission = submissionsInfo.find(
          (submissionInfo) =>
            submissionInfo.SubmissionNumber === claim.SubmissionNumber
        );

        if (submission) {
          remainingSubmissions = remainingSubmissions.filter(
            (submission) =>
              submission.SubmissionNumber !== claim.SubmissionNumber
          );
          return {
            submission: submission,
            dateSubmitted: submission.dateSubmitted,
            claim: claim,
            key: claim.key,
          };
        }
        return {
          submission: null,
          dateSubmitted: claim.dateSubmitted,
          claim: claim,
          key: claim.key,
        };
      });
      remainingSubmissions = remainingSubmissions.map((submission) => ({
        submission: submission,
        dateSubmitted: submission.dateSubmitted,
        key: submission.key,
        claim: null,
      }));
      const claims = [...claimsSubmission, ...remainingSubmissions].sort(
        (claimA, claimB) => claimB.dateSubmitted - claimA.dateSubmitted
      );
      setClaimsList(claims);
      setClaimsExpanded(initialClaimsExpanded);
    } else {
      setClaimsList([]);
    }
  }, [userClaimsList]);

  return (
    <div>
      <Table className={styles.claimsActivityTable}>
        <TableHead>
          <TableRow>
            <TableHeader className={styles.claimDateColumn}>
              {locale.dataTable.dateSubmitted}
            </TableHeader>
            <TableHeader>{locale.dataTable.referenceNumber}</TableHeader>
            <TableHeader className={styles.claimStatusColumn} />
          </TableRow>
        </TableHead>
        <TableBody>
          {claimsList.map((item, index) => {
            const isExpanded = claimsExpanded[item.key];
            return (
              <Fragment key={index}>
                <TableRow
                  onClick={() => toggleClaim(item.key)}
                  className={cx(
                    styles.collapsibleRowRoot,
                    isExpanded && styles.open
                  )}
                >
                  <TableCell className={styles.claimDateColumn}>
                    <Icon name="ArrowRight" className={styles.arrowIcon} />
                    <span>{item.dateSubmitted.format("MMM DD, YYYY")}</span>
                  </TableCell>
                  <TableCell>
                    {item.claim
                      ? `${locale.dataTable.claim} #${item.claim.ClaimNumber}`
                      : `${locale.dataTable.submission} #${item.submission.SubmissionNumber}`}
                  </TableCell>
                  <TableCell className={styles.claimStatusColumn} align="right">
                    {item.claim ? (
                      <Badge
                        appearance={
                          item.claim.Status === "Closed" ? "neutral" : "success"
                        }
                      >
                        {item.claim.Status === "Closed" ? "Closed" : "Open"}
                      </Badge>
                    ) : (
                      ""
                    )}
                  </TableCell>
                </TableRow>
                <TableRow className={styles.collapsibleRow}>
                  <TableCell colSpan="3">
                    <Collapse isOpened={isExpanded}>
                      <div className={styles.collapsibleRowContent}>
                        <ClaimDetails
                          item={item}
                          userDetails={userDetails}
                          userClaimsConstants={userClaimsConstants}
                        />
                      </div>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

ClaimsList.propTypes = {
  userClaimsList: PropTypes.object,
  userClaimsConstants: PropTypes.object,
};

export default ClaimsList;
