import { Link as DSLink } from "@icg360/design-system";

type EmailIconLinkProps = {
  email: string;
};

export const EmailIconLink = ({ email }: EmailIconLinkProps) => (
  <DSLink href={`mailto:${email}`} leadingIcon="Envelope">
    {email}
  </DSLink>
);
