import { useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  Heading,
  MarketingIcon,
  Span,
} from "@icg360/design-system";

import styles from "./featured-offers-shingle-reseal.module.scss";

type FeaturedOfferShingleResealProps = {
  offeringId: string;
};

export const FeaturedOfferShingleReseal = ({
  offeringId,
}: FeaturedOfferShingleResealProps) => {
  const navigate = useNavigate();

  return (
    <Card className={styles.cardDS}>
      <div className={styles.title}>
        <Span className={styles.iconDS}>
          <MarketingIcon name="Present" />
        </Span>
        <Heading size="md">Stay ready for hurricane season</Heading>
      </div>
      <div className={styles.content}>
        <Heading size="md" className={styles.heroTextDS}>
          Join thousands preparing for hurricane season. Sign up for a chance to
          get shingle resealing.
        </Heading>
        <Button
          onClick={() =>
            navigate(`/my/shingle-opportunity/${offeringId}`, {
              state: { from: "/my/overview" },
            })
          }
          data-testid="get-started-btn"
        >
          Get started
        </Button>
      </div>
    </Card>
  );
};
