import { Link as DSLink } from "@icg360/design-system";

type PhoneIconLinkProps = {
  phone: string;
};

export const PhoneIconLink = ({ phone }: PhoneIconLinkProps) => (
  <DSLink href={`tel:${phone.replace(/\D/g, "")}`} leadingIcon="Phone">
    {phone}
  </DSLink>
);
