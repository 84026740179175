import { useNavigate } from "react-router-dom";

import {
  Button,
  Link as DSLink,
  Heading,
  Icon,
  Span,
} from "@icg360/design-system";

import { ShingleResealLayout } from "pages/shingle-reseal/shingle-reseal-layout";

import styles from "./shingle-reseal.module.scss";

export const Enrolled = () => {
  const navigate = useNavigate();
  return (
    <ShingleResealLayout
      step={6}
      heading="You're all set!"
      subheading="We've sent you a confirmation email. Here's what to expect:"
    >
      <div className={styles.enrolledGrid}>
        <div className={styles.iconWrapper}>
          <div className={styles.icon}>
            <Icon name="Clock" />
          </div>
          <div className={styles.dashedLine} />
        </div>
        <Heading size="sm" className={styles.stepNumberDS}>
          1
        </Heading>
        <Span medium>Hang tight while LiftLock monitors the weather</Span>

        <div className={styles.iconWrapper}>
          <div className={styles.icon}>
            <Icon name="Alert" />
          </div>
          <div className={styles.dashedLine} />
        </div>
        <Heading size="sm" className={styles.stepNumberDS}>
          2
        </Heading>
        <Span medium>Get notified if selected</Span>

        <div className={`${styles.lastRow} ${styles.icon}`}>
          <Icon name="ServiceReminder" />
        </div>
        <div className={styles.lastRow}>
          <Heading size="sm" className={styles.stepNumberDS}>
            3
          </Heading>
        </div>
        <div className={styles.lastRowContent}>
          <Span medium>Get loose shingles resealed</Span>
          <div>
            <Span color="quiet">
              A trained professional will arrive before hurricane makes landfall
              and reseal loose shingles of your home.
            </Span>
          </div>
        </div>
      </div>
      <div>
        If you have any questions or concerns, contact us at{" "}
        <DSLink href="mailto:homeservices@sagesure.com">
          homeservices@sagesure.com
        </DSLink>
        .
      </div>
      <div className={styles.confirmationButtons}>
        <Button
          onClick={() => navigate("/my/settings/perks")}
          data-testid="shingle-done"
        >
          Done
        </Button>
      </div>
    </ShingleResealLayout>
  );
};
