import cx from "classnames";
import { Field, Form, useFormikContext } from "formik";

import { Alert, Button, Icon, Tooltip } from "@icg360/design-system";

import { InputField } from "components/shared/form-fields";
import { en as locale } from "locale";

import styles from "./bill-pay.module.scss";

export const BillPayForm = () => {
  const { isSubmitting, isValid, status } = useFormikContext();

  return (
    <Form id="bill-payment" name="bill-payment" className={styles.form}>
      <Field
        name="policyNumber"
        title={<span className={styles.fieldLabel}>Policy #</span>}
        component={InputField}
        placeholder="Enter 10 or 12 Digit Policy Number"
      />
      <Field
        name="zipCode"
        title={
          <span className={cx(styles.fieldLabel, styles.withTooltip)}>
            {locale.paymentPage.zipCode}
            <Tooltip content={locale.paymentPage.tooltips.zipCode}>
              <span className={styles.questionIcon}>
                <Icon name="Question" size="sm" />
              </span>
            </Tooltip>
          </span>
        }
        component={InputField}
        placeholder="Enter 5-digit Property Zip Code"
      />
      <Button
        appearance="primary"
        type="submit"
        disabled={!isValid || isSubmitting}
        data-testid="bill-pay-make-payment-btn"
        fluid
      >
        {isSubmitting ? locale.buttons.loading : locale.buttons.makeAPayment}
      </Button>
      {status?.type === "error" ? (
        <Alert
          appearance="danger"
          title={locale.paymentPage.error.title}
          description={status.message}
          className={styles.statusToast}
        />
      ) : null}
      {status?.type === "success" ? (
        <Alert
          appearance="success"
          title={locale.easypayEnroll.successToast.title}
          description={status.message}
          className={styles.statusToast}
        />
      ) : null}
    </Form>
  );
};
