import { ReactNode, createContext, useCallback, useState } from "react";

import { useAuthAppData } from "components/root/use-auth-app-data";
import type {
  CommonPolicyDataQuery,
  GetPolicyQuery,
  UserPolicyIDsQuery,
} from "gql/__generated__/hooks";
import { Policy } from "gql/types";
import { setCookie, trackEvent } from "utils";

type Popups =
  | "addPolicy"
  | "unlinkPolicy"
  | "confirmationModal"
  | "confirmationLeakBotModal"
  | "modifyPaperlessEmailModal"
  | "modifyPaperlessDocumentsEmailModal"
  | "paperlessSuccessModal"
  | "paperlessBillingSuccessModal"
  | "paperlessDocumentsSuccessModal"
  | "policySuccessModal"
  | "invalidPolicyModal"
  | "enrolledInEasyPayModal"
  | "easyPayEnrollmentModal"
  | "paperlessModal"
  | "confirmExitModal"
  | "ieModal"
  | "";

export type AuthAppContextState = {
  selectedPolicyId: string;
  selectedPolicy: GetPolicyQuery["getPolicy"] | null;
  propertyAddress: Policy["propertyAddress"] | null;
  popupList: Popups[];
  displayedModal: Popups;
  policy: Policy;
  policies: UserPolicyIDsQuery["userPolicies"];
  userBilling: CommonPolicyDataQuery["userBilling"] | null;
  userCarrierInfo: CommonPolicyDataQuery["userCarrierInfo"] | null;
  userDetails: CommonPolicyDataQuery["userDetails"] | null;
  userDocuments: CommonPolicyDataQuery["userDocuments"] | null;
  userInfo: CommonPolicyDataQuery["userBasicInfo"] | null;
  userInsuranceRep: CommonPolicyDataQuery["userInsuranceRep"] | null;
  userPolicyProperties: CommonPolicyDataQuery["userPolicyProperties"] | null;
  isPolicyDataReady: boolean;
};

export type AuthAppContextInterface = {
  setPolicy: (policy: Policy) => void;
  setPolicies: (policies: UserPolicyIDsQuery["userPolicies"]) => void;
  setSelectedPolicy: (selectedPolicy: GetPolicyQuery["getPolicy"]) => void;
  setUserBilling: (userBilling: CommonPolicyDataQuery["userBilling"]) => void;
  setUserCarrierInfo: (
    userCarrierInfo: CommonPolicyDataQuery["userCarrierInfo"]
  ) => void;
  setUserDetails: (userDetails: CommonPolicyDataQuery["userDetails"]) => void;
  setUserDocuments: (
    userDocuments: CommonPolicyDataQuery["userDocuments"]
  ) => void;
  setUserInfo: (userInfo: CommonPolicyDataQuery["userBasicInfo"]) => void;
  setUserInsuranceRep: (
    userInsuranceRep: CommonPolicyDataQuery["userInsuranceRep"]
  ) => void;
  setUserPolicyProperties: (
    userPolicyProperties: CommonPolicyDataQuery["userPolicyProperties"]
  ) => void;
  addPopup: (popup: string, priority?: boolean) => void;
  removePopup: (popup: string) => void;
  clearPopups: () => void;
  refetchPolicy: () => void;
  refetchPolicies: () => void;
};

const initialStateDefault: AuthAppContextState = {
  selectedPolicyId: "",
  selectedPolicy: null,
  propertyAddress: null,
  popupList: [],
  displayedModal: "",
  policy: { policyId: "", insightPolicyId: "" },
  policies: [],
  userBilling: null,
  userCarrierInfo: null,
  userDetails: null,
  userDocuments: null,
  userInfo: null,
  userInsuranceRep: null,
  userPolicyProperties: null,
  isPolicyDataReady: false,
};

const initialDispatchDefault: AuthAppContextInterface = {
  setPolicy: (_policy: Policy) => null,
  setPolicies: (_policies: UserPolicyIDsQuery["userPolicies"]) => null,
  setSelectedPolicy: (_selectedPolicy: GetPolicyQuery["getPolicy"]) => null,
  setUserBilling: (_userBilling: CommonPolicyDataQuery["userBilling"]) => null,
  setUserCarrierInfo: (
    _userCarrierInfo: CommonPolicyDataQuery["userCarrierInfo"]
  ) => null,
  setUserDetails: (_userDetails: CommonPolicyDataQuery["userDetails"]) => null,
  setUserDocuments: (_userDocuments: CommonPolicyDataQuery["userDocuments"]) =>
    null,
  setUserInfo: (_userInfo: CommonPolicyDataQuery["userBasicInfo"]) => null,
  setUserInsuranceRep: (
    _userInsuranceRep: CommonPolicyDataQuery["userInsuranceRep"]
  ) => null,
  setUserPolicyProperties: (
    _userPolicyProperties: CommonPolicyDataQuery["userPolicyProperties"]
  ) => null,
  addPopup: (_popup: string, _priority?: boolean) => null,
  removePopup: (_popup: string) => null,
  clearPopups: () => null,
  refetchPolicy: () => null,
  refetchPolicies: () => null,
};
const AuthAppContext = createContext<AuthAppContextState>(initialStateDefault);
const AuthAppDispatchContext = createContext<AuthAppContextInterface>(
  initialDispatchDefault
);

// This is broken out into a hook mostly to make testing easier
export const useAuthAppInternals = (initialState = initialStateDefault) => {
  const [state, setState] = useState(initialState);

  const setPolicy = useCallback((policy: Policy) => {
    setCookie("mss-selected-policy", btoa(JSON.stringify(policy)));

    setState((prevState) => {
      trackEvent("Policy Selected", {
        policySequence: (prevState?.policies?.indexOf(policy) ?? 0) + 1,
      });

      return {
        ...prevState,
        isPolicyDataReady: prevState.selectedPolicyId === policy.policyId,
        selectedPolicyId: policy?.policyId ?? "",
        propertyAddress: policy.propertyAddress,
        popupList: [],
        displayedModal: "",
        policy,
      };
    });
  }, []);

  const setSelectedPolicy = useCallback(
    (selectedPolicy: GetPolicyQuery["getPolicy"]) => {
      setState((prevState) => ({ ...prevState, selectedPolicy }));
    },
    []
  );

  const setPolicies = useCallback(
    (policies: UserPolicyIDsQuery["userPolicies"]) => {
      setState((prevState) => ({ ...prevState, policies }));
    },
    []
  );

  const setUserBilling = useCallback(
    (userBilling: CommonPolicyDataQuery["userBilling"]) => {
      setState((prevState) => ({ ...prevState, userBilling }));
    },
    []
  );

  const setUserCarrierInfo = useCallback(
    (userCarrierInfo: CommonPolicyDataQuery["userCarrierInfo"]) => {
      setState((prevState) => ({ ...prevState, userCarrierInfo }));
    },
    []
  );

  const setUserDetails = useCallback(
    (userDetails: CommonPolicyDataQuery["userDetails"]) => {
      setState((prevState) => ({ ...prevState, userDetails }));
    },
    []
  );

  const setUserDocuments = useCallback(
    (userDocuments: CommonPolicyDataQuery["userDocuments"]) => {
      setState((prevState) => ({ ...prevState, userDocuments }));
    },
    []
  );

  const setUserInfo = useCallback(
    (userInfo: CommonPolicyDataQuery["userBasicInfo"]) => {
      setState((prevState) => ({ ...prevState, userInfo }));
    },
    []
  );

  const setUserInsuranceRep = useCallback(
    (userInsuranceRep: CommonPolicyDataQuery["userInsuranceRep"]) => {
      setState((prevState) => ({ ...prevState, userInsuranceRep }));
    },
    []
  );

  const setUserPolicyProperties = useCallback(
    (userPolicyProperties: CommonPolicyDataQuery["userPolicyProperties"]) => {
      setState((prevState) => ({ ...prevState, userPolicyProperties }));
    },
    []
  );

  const addPopup = useCallback((popup: Popups, priority = false) => {
    setState((prevState) => {
      const { popupList } = prevState;
      if (priority) {
        popupList.unshift(popup);
      } else {
        popupList.push(popup);
      }

      return {
        ...prevState,
        popupList,
        displayedModal: popupList[0],
      };
    });
  }, []);

  const removePopup = useCallback((popup: Popups) => {
    setState((prevState) => {
      const { popupList } = prevState;
      const index = popupList.indexOf(popup);
      if (index > -1) {
        popupList.splice(index, 1);
      }

      return {
        ...prevState,
        popupList,
        displayedModal: popupList.length > 0 ? popupList[0] : "",
      };
    });
  }, []);

  const clearPopups = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      popupList: [],
      displayedModal: "",
    }));
  }, []);

  const setIsPolicyDataReady = useCallback((isPolicyDataReady: boolean) => {
    setState((prevState) => ({ ...prevState, isPolicyDataReady }));
  }, []);

  const dispatchMethods = {
    setPolicy,
    setPolicies,
    setSelectedPolicy,
    setUserBilling,
    setUserCarrierInfo,
    setUserDetails,
    setUserDocuments,
    setUserInfo,
    setUserInsuranceRep,
    setUserPolicyProperties,
    addPopup,
    removePopup,
    clearPopups,
  };

  const { refetchPolicy, refetchPolicies } = useAuthAppData(
    state,
    dispatchMethods,
    setIsPolicyDataReady
  );

  return {
    state,
    dispatchMethods: { ...dispatchMethods, refetchPolicy, refetchPolicies },
  };
};

function AuthAppProvider({ children }: { children: ReactNode }) {
  const { state, dispatchMethods } = useAuthAppInternals();

  return (
    <AuthAppContext.Provider value={state}>
      <AuthAppDispatchContext.Provider value={dispatchMethods}>
        {children}
      </AuthAppDispatchContext.Provider>
    </AuthAppContext.Provider>
  );
}

export {
  AuthAppContext,
  AuthAppDispatchContext,
  AuthAppProvider,
  initialStateDefault,
  initialDispatchDefault,
};
