import type { MssConfig } from "./types";

export const CONFIG: MssConfig = {
  APP_ENV: "production",
  AUTH0_DOMAIN: "stage-ss.auth0.com",
  AUTH0_CLIENT_ID: "gimV99LhcA1ypauxHD8b5KFvnxlDpQBq",
  COOKIE_DOMAIN: "my.sagesure.com",
  KEYSTONE_API_HREF: "https://services.sagesure.com/cru-4/keystone",
  PAYMENT_API_HREF: "https://services.sagesure.com/cru-4/payments/graphql",
  MIXPANEL_TOKEN: "98e6dc510823bd71294a21332b68c8f9",
  LAUNCH_DARKLY_CLIENT_ID: "5ac7bd1ceee8b92c5fbf583c",
  KEYSTONE_PROXY_HREF: "https://my.sagesure.com/p",
  GOOGLE_MAPS_KEY: "AIzaSyDllbO3M5kKPVmcw-TZOv5XIbWXcVhdtLE",
  CHAT_UUID: "script_18854009285f5941ec99a357.62831612",
  CHAT_CHANNEL: "MySageSureChat",
  CHAT_CHANNEL_UUID: "58ec6835-8350-4380-8d31-5899ce3e0bbf",
  IPC_MANAGER_URL: "https://manager.sagesure.com/quotes-and-policies",
  FNOL_DEFINITIONS_URL:
    "https://sagesure-claims-fnol-forms-ss-prod.s3.amazonaws.com/public",
  GOOGLE_OAUTH_CLIENT_ID:
    "71648894609-77h10a2n5npjf62l5nu6vbmthnuc0n38.apps.googleusercontent.com",
  THUMBTACK_API_HREF: "https://api.thumbtack.com/v1",
  THUMBTACK_AUTH:
    "U2FnZXN1cmU6WDBNMVg4aEZ1RjFlS3FHLTNfd2JaU3pMQWRmcGlGUWEwZWdncGdBNEpuWT0=",
  OTEL_COLLECTOR: "/p/telemetry/traces",
  QUALTRICS_PROJECT_ID: "ZN_4SFAITUAxK8EvIy",
};
