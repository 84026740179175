import { useContext, useState } from "react";

import { Card, Heading, Icon, Span } from "@icg360/design-system";

import { EmailIconLink, PhoneIconLink } from "components/common/link";
import { AuthAppContext } from "components/root/auth-app-provider";
import { formatPhone, isFormattedPhone, trackEvent } from "utils";

import styles from "./cancelled-policy-sidebar.module.scss";

export const CancelledPolicySidebar = () => {
  const [showText, setShowText] = useState(false);
  const { userInsuranceRep } = useContext(AuthAppContext);
  const {
    agentEmail = "",
    agencyName,
    agentStreetNumber,
    agentStreetName,
    agentZipCode,
    agentCity,
    agentState,
    agentPhone: unformattedPhone = "",
  } = userInsuranceRep ?? {};
  const agentPhone = isFormattedPhone(unformattedPhone)
    ? unformattedPhone
    : formatPhone(unformattedPhone ?? "");

  return (
    <Card>
      <button
        className={styles.sidebarHeading}
        onClick={() => {
          setShowText(!showText);
          trackEvent("writeout_reinstateClicked");
        }}
      >
        <Heading size="md">How do I get my policy back?</Heading>
        <div className={styles.arrowIcon}>
          <Icon name={showText ? "ArrowUp" : "ArrowDown"} />
        </div>
      </button>

      {showText ? (
        <div className={styles.textContent}>
          If you submit acceptable proof after policy cancellation, contact your
          agent for next steps to obtain coverage for your home.
          <div className={styles.agentAddress}>
            {agencyName ? <Span bold>{agencyName}</Span> : null}
            {agentStreetNumber && agentStreetName ? (
              <Span>{`${agentStreetNumber} ${agentStreetName}`}</Span>
            ) : null}
            <Span>{`${agentCity ?? ""}, ${agentState ?? ""} ${
              agentZipCode ?? ""
            }`}</Span>
          </div>
          <EmailIconLink email={agentEmail ?? ""} />
          <PhoneIconLink phone={agentPhone ?? ""} />
        </div>
      ) : null}
    </Card>
  );
};
